import { SearchInput } from './components/SearchInput/SearchInput';
import { FilterAndInputWrapper } from './SearchResultPage.styled';

import { ResultInner } from '../components/ResultInner';

import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useGetSearchVideosQuery, useGetSuggestionsQuery } from '@/api/searchApi';
import { MyInput } from '@/components/Shared/MyInput';
import { useAppDispatch, useHandlingError } from '@/hooks';
import { Inner } from '@/pages/MainPage/MainPage.styled';
import { setSearchQuery } from '@/store/slices/searchQuery';

export const SearchResultPage = () => {
  const [params, setParams] = useSearchParams();

  // const [isOnlyTranscripts, setIsChecked] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSearchQuery(''));
  }, []);

  const isOnlyTranscripts = params.get('onlyTranscripts') === 'true';

  const { data, isFetching, isLoading, error } = useGetSearchVideosQuery(
    { query: params.get('search'), onlyTranscripts: isOnlyTranscripts },
    { skip: !params.get('search') },
  );

  const { data: suggestions = [] } = useGetSuggestionsQuery(
    { query: params.get('search') },
    { skip: !params.get('search') },
  );
  const { catchError } = useHandlingError();

  useEffect(() => {
    catchError(error);
  }, [error]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParams((params) => {
      params.set('onlyTranscripts', e.target.checked.toString());
      return params;
    });
  };

  const filterVideos = () => {
    return data
      ?.map((vid) => {
        const filteredCues = vid.cues.filter((cue) => {
          return params
            .get('search')
            ?.replace(/(<([^>]+)>)/gi, '')
            .includes(cue.content.replace(/(<([^>]+)>)/gi, ''));
        });

        return {
          ...vid,
          cues: filteredCues,
        };
      })
      .filter((vid) =>
        vid.cues.some((cue) =>
          params
            .get('search')
            ?.replace(/(<([^>]+)>)/gi, '')
            .includes(cue.content.replace(/(<([^>]+)>)/gi, '')),
        ),
      );
  };

  return (
    <>
      <FilterAndInputWrapper>
        <SearchInput suggestionsList={suggestions} />
        <MyInput
          type="checkbox"
          label="Искать по точному совпадению"
          checked={isOnlyTranscripts}
          onChange={handleChange}
        />
      </FilterAndInputWrapper>
      <Inner>
        <ResultInner
          data={isOnlyTranscripts ? filterVideos() : data}
          isLoading={isLoading || isFetching}
          count={isOnlyTranscripts ? filterVideos()?.length : data?.length}
        />
      </Inner>
    </>
  );
};

export default SearchResultPage;
