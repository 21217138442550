import { Main } from '../../../App.styled';
import Container from '../../../components/Shared/Container/Container';
import Header from '../Header';
import { ResultWrapper } from '../Search.styled';

import { Outlet, useSearchParams } from 'react-router-dom';

import { useGetSearchVideosQuery } from '@/api/searchApi';
import { useMatchMedia } from '@/hooks';

const ResultsLayout = () => {
  const [params] = useSearchParams();
  const isMobile = useMatchMedia('(max-width: 1000px)');

  useGetSearchVideosQuery({ query: params.get('search'), onlyTranscripts: false }, { skip: !params.get('search') });

  return (
    <ResultWrapper isMobile={isMobile}>
      <Header />
      <Main>
        <Container>
          <Outlet />
        </Container>
      </Main>
    </ResultWrapper>
  );
};

export default ResultsLayout;
